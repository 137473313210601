export default {
  importAssignments,
  importBudgetCodes,
  importDestinations,
  importDrivers,
  importFundingSources,
  importLocations,
  importSpecialDates,
  importTrips,
  importUsers,
  importVehicles,

  importFromTraversa,
};

////////////

import api from '@/shared/api.service';

async function importAssignments(obj) {
  return await api.post('/api/import/assignments', obj);
}

async function importBudgetCodes(obj) {
  return await api.post('/api/import/budget-codes', obj);
}

async function importDestinations(obj) {
  return await api.post('/api/import/destinations', obj);
}

async function importDrivers(obj) {
  return await api.post('/api/import/drivers', obj);
}

async function importFundingSources(obj) {
  return await api.post('/api/import/funding-sources', obj);
}

async function importLocations(obj) {
  return await api.post('/api/import/locations', obj);
}

async function importSpecialDates(obj) {
  return await api.post('/api/import/dates', obj);
}

async function importTrips(obj) {
  return await api.post('/api/import/trips', obj);
}

async function importUsers(obj) {
  return await api.post('/api/import/users', obj);
}

async function importVehicles(obj) {
  return await api.post('/api/import/vehicles', obj);
}

async function importFromTraversa(obj) {
  return await api.post('/api/import/traversa', obj);
}
